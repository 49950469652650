import * as queryString from 'query-string';
import SessionStorage from '@optum-wvie/dynamic-ui-framework/src/sessionStorage';

//////////////////////////////////////////////////////////////////////////////////////
//START TENANT CONFIG SECTION
//Update this section for every new tenant. The functions must be synchronous.
import {
  isImplemented as isImpl_WV,
  getConfig as getConfig_WV,
  determineEnvironment as getEnv_WV,
} from './config_WV';

const TENANTS = {
  WV: {
    isImpl: isImpl_WV,
    getEnv: getEnv_WV,
    getConfig: getConfig_WV,
  },
};

//END TENANT CONFIG SECTION
//////////////////////////////////////////////////////////////////////////////////////

let environment: string = null;
let tenantCode: string = null;



//We are in an implementation build.
tenantCode = 'WV';
environment = TENANTS.WV.getEnv();


if (!environment) {
  environment = determineBaseEnvironment();
}

if (!tenantCode) {
  tenantCode = determineBaseTenant(environment);
  SessionStorage.setItem(`IE-${environment}-CLP-TENANT`, tenantCode);
}

const getTenantConfig = TENANTS[tenantCode].getConfig;

export let config = initConfig();

function initConfig() {
  //Retrieve config for all enviroments.
  let baseConfig: any = {
    environment,
    languages: [
      {
        locale: 'en', //TODO: dialect?
        name: 'English',
      },
      {
        locale: 'es',
        name: 'Español',
      },
    ],
    removeCCAndCSLOB: false,
    ignoreUARNetworkId: 'Z999999',
    appProcessingPath: '/api/ie/applicationProcessing/v2',
    domainPath: '/api/ie/domain/v2',
    formPath: '/api/ie/forms/{tenantCode}/v3.0',
    defaultExpiryMs: 15 * 60000,
    countdownTimerMs: 5 * 60000,
    portalName: 'clp',
    tenant_c: 'wv',
    tenant_id: 1,
    openId_clientId: 'CLPWV',
    openId_grantType: 'authorization_code',
    openId_clientSecret:
      '78fc015190d353edf398b9d898e1d5fc3cfcfeef90463cfeb3aac6604a3e807c67de2bc707be79b369292f9c07c71c8c',

    //CLP Forms Endpoints
    forms_clientScheduler: '/clp/ClientScheduler',
    forms_ConfigurationTab: '/clp/ConfigurationTab',
    forms_scheduleAppointment: '/clp/ScheduleAppointment',
    forms_ClientSearchLookup: '/clp/ClientSearchLookup',
    forms_ViewMaintainAppointment: '/clp/ViewMaintainAppointment',
    forms_CreateSchedule: '/clp/CreateSchedule',
    forms_Calendar: '/clp/Calendar',
    forms_MyConfiguration: '/clp/MyConfiguration',
    forms_PrintForms: '/clp/printForms',
    forms_MdmSearchForms: '/clp/MdmSearch',
    forms_quickLinksForms: '/clp/QuickLinks',
    forms_HeaderSerarch: '/clp/HeaderSearch',
    forms_InboxWidgetForm: '/clp/InboxWidget',

    //Error Message Json
    validationErrorMsgs_RecLogErrors: '/clp/ReceptionLogErrors',
    validationErrorMsgs_MdmSearch: '/clp/MdmSearchErrors',
    validationErrorMsgs_ScheduleApptErrors: '/clp/ScheduleAppointmentErrors',
    validationErrorMsgs_ConfigErrors: '/clp/SchedulingConfigurationErrors',
    validationErrorMsgs_CreateSchedulErrors: '/clp/CreateScheduleErrors',
    validationErrorMsgs_ViewMaintain: '/clp/ViewMaintainErrors',
    validationErrorMsgs_LookupModlErrors: '/clp/LookupModalErrors',
    validationCLPCS_ErrorMsgs: '/clp/ClientSchedularErrors',

    //WV specific Java endpoints
    GetDropDownValuesForUUID:
      '/WVIE/clientscheduling/v1.0/getCSCodeReferUIData',
    getCSWorkerProfileData:
      '/WVIE/clientscheduling/v1.0/getCSWorkerProfileData',
    statecounty: '/WVIE/stcnty/v1.0/statecounty?state={state}',
    viewReceptionLogData: '/WVIE/clientscheduling/v1.0/viewReceptionLogData',
    viewAndSearchRecptionLogData:
      '/WVIE/clientscheduling/v1.0/filterViewReceptionLog',
    saveAddEntryDetails: '/WVIE/clientscheduling/v1.0/saveAddEntryDetails',
    getCountyConfig: '/WVIE/clientscheduling/v1.0/getCountyConfig',
    saveOfficeLevelCountyConfig:
      '/WVIE/clientscheduling/v1.0/saveOfficeLevelCountyConfig',
    getUnitLevelConfig: '/WVIE/clientscheduling/v1.0/getUnitLevelConfig',
    saveUnitLevelConfiguration:
      '/WVIE/clientscheduling/v1.0/saveUnitLevelConfiguration',
    getWorkerLevelConfig: '/WVIE/clientscheduling/v1.0/getWorkerLevelConfig',
    saveWorkerLevelConfiguration:
      '/WVIE/clientscheduling/v1.0/saveWorkerLevelConfiguration',
    saveClientAppointments:
      '/WVIE/clientscheduling/v1.0/saveClientAppointments',
    retrieveReceptionLogEntryData:
      '/WVIE/clientscheduling/v1.0/retrieveReceptionLogEntryData',
    getReceptionEntryHistoryDetails:
      '/WVIE/clientscheduling/v1.0/getReceptionEntryHistoryDetails',
    updateStatusForReceptionEntryDetails:
      '/WVIE/clientscheduling/v1.0/updateStatusForReceptionEntryDetails',
    searchForClientInformation: '/wv/tenantWrapper/getclientInformation',
    getWorkerSchdApptTimeSlot:
      '/WVIE/clientscheduling/v1.0/getWorkerSchdApptTimeSlot',
    searchClientAppointments:
      '/WVIE/clientscheduling/v1.0/searchClientAppointments',
    retrieveClientSchdAppointmentDetails:
      '/WVIE/clientscheduling/v1.0/retrieveClientSchdAppointmentDetails',
    saveMultiReviewAppointments:
      '/WVIE/clientscheduling/v1.0/saveMultiReviewAppointments',
    updateStatusForMultipleAppointments:
      '/WVIE/clientscheduling/v1.0/updateStatusForMultipleAppointments',
    monthlyScheduleSubmit: '/WVIE/clientscheduling/v1.0/createMonthlySchedule',
    unitScheduleSubmit: '/WVIE/clientscheduling/v1.0/createUnitSchedule',
    retrieveUnitScheduleVersion:
      '/WVIE/clientscheduling/v1.0/getUnitScheduleVersionList',
    retrieveMonthlySchedRecurDetails:
      '/WVIE/clientscheduling/v1.0/retrieveMonthlySchedRecurDetails ',
    retrieveUnitSchedRecurDetails:
      '/WVIE/clientscheduling/v1.0/retrieveUnitSchedRecurDetails',
    retrieveMyCalEvents:
      '/WVIE/clientscheduling/v1.0/retrieveSchedCalendarEvents',
    deleteSchedRecurrenceAppointments:
      '/WVIE/clientscheduling/v1.0/deleteSchedRecurrenceAppointments',
    retrieveUnitSchedCalEvents:
      '/WVIE/clientscheduling/v1.0/retrieveUnitSchedCalendarEvents',
    updateMonthlySchedRecurrence:
      '/WVIE/clientscheduling/v1.0/updateMonthlySchedRecurrence',
    createWorkerEnforceUnitSched:
      '/WVIE/clientscheduling/v1.0/createWorkerEnforceUnitSched',
    getEnforceUnitSchedData:
      '/WVIE/clientscheduling/v1.0/getEnforceUnitSchedData',
    deleteWorkerEnforceUnitSched:
      '/WVIE/clientscheduling/v1.0/deleteWorkerEnforceUnitSched',
    updateUnitSchedRecurrence:
      '/WVIE/clientscheduling/v1.0/updateUnitSchedRecurrence',
    getAlertNotifications: '/WVIE/clientscheduling/v1.0/generateSchedUserAlert',
    updateAlertNotifications:
      '/WVIE/clientscheduling/v1.0/updateSchedUserAlert',
    searchNextAvailableWorker:
      '/WVIE/clientscheduling/v1.0/searchNextAvailWorkerOrTimeAppt',
    pathSearchLookup: '/WVIE/clientscheduling/v1.0/searchPathAppInfo',
    getWidgetList: '/WVIE/CLPWidgets/v1.0/getCLPWidgetsData',
    updateWidgetList: '/WVIE/CLPWidgets/v1.0/updateCLPWidgetsData',
    getMonthlyCalEvents:
      '/WVIE/clientscheduling/v1.0/retrieveMonthlyCalendarEvents',
    getWorkerlevelAlertConfig:
      '/WVIE/clientscheduling/v1.0/getCLPWorkerLevelAlertConfig',
    getPATHPrintFormIDList: '/WVIE/CLPForms/v1.0/getPATHPrintFormIDList',
    retrievePATHPrintFormData: '/WVIE/CLPForms/v1.0/retrievePATHPrintFormData',
    getErrorCodes: '/api/ie/referencedata/v2/{tenantCode}/errors/codes',
    getQuickLinks: '/WVIE/CLPForms/v1.0/getQuickLinkURLList',
    getInboxData: '/WVIE/CLPWidgets/v1.0/getPATHInboxWidgetData',

    // WV specific Mule endpoints
    getStateCountyList:
      '/optum/services/wv/clientscheduling/getStateCountyList',
    personSearchMatch: '/optum/services/mdm/individualMatch',
    personSearch: '/optum/services/mdm/individual',
    personMasterRecord: '/optum/services/mdm/individualMasterRecord',
    providerSearchMatch: '/optum/services/mdm/provEmpLocMatch',
    providerSearch: '/optum/services/mdm/provEmpLocSearch',
    providerMasterRecord: '/optum/services/mdm/provEmpLocMasterRecord',
    sortingColumnAPI: '/optum/services/mdm/sorting',

    // Office-365 (Outlook)Specific Endpoints
    getAccessTokenFromRefresh:
      'https://login.microsoftonline.com/wv.gov/oauth2/v2.0/token',
    getAccessAndRefresh:
      'https://login.microsoftonline.com/wv.gov/oauth2/v2.0/token',
    logOutOffice365:
      'https://login.microsoftonline.com/wv.gov/oauth2/logout?post_logout_redirect_uri=',
    getEvents: 'https://graph.microsoft.com/v1.0/me/calendarview?$top=1000&',
    getOutlookConflictList:
      'https://graph.microsoft.com/v1.0/me/calendar/getSchedule',

    // Admin Portal Brodacast messages endpoints
    getBroadCastAlerts: '/api/ie/domain/v2/wv/broadcasts/messages',
    dismissBroadCastAlerts: '/api/ie/domain/v2/wv/broadcasts/{alertId}/messages/dismiss',

    // CS specific endpoints
    csUserUpdate: '/oscar-service/api/cs/v1/agent/unique/',
    docMgmtInsert: '/oscar-service/api/cs/v1/documentMgmt/insert',
    getCountyWorkers: '/oscar-service/api/cs/v1/getCountyWorkers/',
    getCaseBasedServiceSites: '/oscar-service/api/cs/v1/getCaseServiceSiteAddress/{CASE_NUMBER}',

    // MDM redirect URL on SSO Steward link
    mdmStewardUrl:
      'http://rapmdmd1:8080/e360/com.informatica.tools.mdm.web.auth/login',

    // User Access forms load Endpoints
    userAccess: '{userAccessFormBaseUrl}/shared/UserAccess',
    userAccessRequest:
      '{gatewayBaseUrl}/api/ie/usrmgmt/v2/{tenantCode}/access-requests',
    userAccessRequests:
      '{gatewayBaseUrl}/api/ie/usrmgmt/v2/{tenantCode}/access-requests',
    roles: '{gatewayBaseUrl}/api/ie/usrmgmt/v2/{tenantCode}/roles',
    orgs: '{gatewayBaseUrl}/api/ie/usrmgmt/v2/{tenantCode}/orgs',
    allOrgs: '{gatewayBaseUrl}/api/ie/usrmgmt/v2/{tenantCode}/all-orgs',
    accessRequest:
      '{gatewayBaseUrl}/api/ie/usrmgmt/v2/{tenantCode}/access-requests/{accessRequestId}',
    searchCounties: '{gatewayBaseUrl}/api/ie/usrmgmt/v2/{tenantId}/counties',
    userAccessRequestsSearch:
      '{gatewayBaseUrl}/api/ie/usrmgmt/v2/{tenantCode}/access-requests/search',

    // User Management Endpoints
    userInfo: '/api/ie/usrmgmt/v2/authorize/wv/userinfo-v2',
    singleSignOn: '/api/ie/usrmgmt/v2/authorize/{tenantCode}/sso',
    revokeToken: '/api/ie/usrmgmt/v2/authorize/{tenantCode}/token/revoke',
    isUserLoggedIn: '/api/ie/usrmgmt/v2/{tenantId}/isuserloggedin',

    // Interactive Forms Endpoints
    getCincomXml: '/wvpath/forms/v1/getRequestPayload',
    getSingleAptnoticeForm: '/wvpath/forms/v1/correspondence/7151',
    getDocument: '/wvpath/forms/v1/getDocument',

    //IncientForm Changes
    forms_IncidentForm: '/clp/IncidentForm',
    getUserAccountDetails:
      '{gatewayBaseUrl}{appProcessingPath}/secure/getUserAccountDetails/{tenantCode}',

    // getUserAccountDetails:
    // '{gatewayBaseUrl}/api/ie/applicationProcessing/v3/{tenantCode}/user/account/details',
    gatewayBaseUrlSsWv: '/services/wv/self-service-portal/incident/create',
    form_timeStudyEntry:
      '{formGatewayBaseUrl}{formPath}/ap/TimeStudySheetEntry',
    form_timeStudyEntryRODCA:
      '{formGatewayBaseUrl}{formPath}/ap/TimeStudySheetEntryRODCA',
    form_timeStudyDashboardSupervisor:
      '{formGatewayBaseUrl}{formPath}/ap/TimeStudyDashSupervisor',
    form_timeStudyDashboardWorker:
      '{formGatewayBaseUrl}{formPath}/ap/TimeStudyDashWorker',
    form_timeStudyWorkerHistory:
      '{formGatewayBaseUrl}{formPath}/ap/TimeStudyWorkerHistory',
    form_timeStudySupervisorHistory:
      '{formGatewayBaseUrl}{formPath}/ap/TimeStudySupervisorHistory',
    form_timeStudyReports: '{formGatewayBaseUrl}{formPath}/ap/TimeStudyReports',
    form_timeStudyCategoryReasonCodes:
      '{formGatewayBaseUrl}{formPath}/ap/TimeStudyRODCAHelper',
    form_timeStudyCategoryStatusCodes:
      '{formGatewayBaseUrl}{formPath}/ap/TimeStudyHelper',
    form_timeStudySearchIntakeModal:
      '{formGatewayBaseUrl}{formPath}/ap/TimeStudySearchIntakeModal',
    form_timeStudyAdmin:
      '{formGatewayBaseUrl}{formPath}/ap/TimeStudyAdmin',
    fetchScheduledTimeStudy:
      '{gatewayBaseUrl}{domainPath}/{tenantCode}/time-study/timesheet/searchTimeSchedule',
    saveScheduledTimeStudy:
      '{gatewayBaseUrl}{domainPath}/{tenantCode}/time-study/timesheet/createSchedule',
    form_timeStudyAddModal:
      '{formGatewayBaseUrl}{formPath}/ap/TimeStudyAdd',
    timeStudyTsGet:
      '{gatewayBaseUrl}{domainPath}/{tenantCode}/time-study/timesheet/{controlId}',
    timeStudyTsCreate:
      '{gatewayBaseUrl}{domainPath}/{tenantCode}/time-study/timesheet/create',
    timeStudyDashboard:
      '{gatewayBaseUrl}{domainPath}/{tenantCode}/time-study/dashboard/{orgId}',
    timeStudyClientSearchCount:
      '{gatewayBaseUrl}{domainPath}/{tenantCode}/applications/client-search/count',
    timeStudyClientSearch:
      '{gatewayBaseUrl}{domainPath}/{tenantCode}/applications/client-search',
    timeStudyEntryRemove:
      '{gatewayBaseUrl}{domainPath}/{tenantCode}/time-study/timesheet/delete/detailId/{detailId}/controlId/{controlId}/seqNbr/{seqNbr}',
    timeStudyCaseSearch:
      '{gatewayBaseUrl}{domainPath}/{tenantCode}/time-study/cases/search',
    timeStudyIntakeOrClientSearch:
      '{gatewayBaseUrl}{domainPath}/{tenantCode}/time-study/intakeOrClient/search',
    timeStudyProviderSearch:
      '{gatewayBaseUrl}{domainPath}/{tenantCode}/time-study/provider/search',
    timeStudyFetchHistory:
      '{gatewayBaseUrl}{domainPath}/{tenantCode}/time-study/history',
    searchUsers: '{gatewayBaseUrl}/api/ie/usrmgmt/v2/{tenantCode}/users/search',
    accountLookup: '{gatewayBaseUrl}/api/ie/usrmgmt/v2/{tenantCode}/access-requests/accessrequest/useraccountlookupdetail/{uuid}',
    checkNetworkId: '{gatewayBaseUrl}/api/ie/usrmgmt/v2/{tenantCode}/access-requests/networkIdExists',
    blueZoneLink: 'blueZone:',
    printForms_ChildSupport:'/wvpath/forms/v1/print/{formId}',
    emailNotification: '/services/esb/mule/framework/v2.0/notification/email'
  };

  //Retrieve environment-level config.
  switch (environment) {
    case 'LOCAL':
      baseConfig = {
        ...baseConfig,
        basePath: '',
        matomoUrl: null,
        matomoSiteId: null,
        matomoCustomDimensionGovIdUsername: null,
        matomoCustomDimensionCorrelationId: null,
        cincomUrl: 'https://apved54358.dmzmgmt.uhc.com:9380/WV_Dev02_CEWeb/api',
        gatewayWvUrl: 'https://wvie-api-gw2.optum.com',
        gatewayIeUrl: 'https://hcck8s-ie-apigw-tst.optum.com',
        //gatewayIeUrl: 'https://ie-apigw-tst.optum.com',
        //gatewayIeUrl: "http://localhost:8080",
        gatewayMDMWvUrl: 'https://wvie-api-gw-test.optum.com',
        //Edit this variable to target localhost fetches.
        // formsBaseUrl:
        // 'https://wvie-api-gw-test2.optum.com/api/ie/forms/wv/{version}',
        formsBaseUrl: "https://wvie-apigw-test-dmz.optum.com/api/ie/forms/wv/{version}",
        // formGatewayBaseUrl:
        // 'https://wvie-api-gw-test2.optum.com',
        formGatewayBaseUrl: "https://agency-portal-fe-wvie-test-k8s-ctc-dmz-nonprod.optum.com",
        // formsBaseUrl:
        //   'https://wvie-api-gw-uat2.optum.com/api/ie/forms/wv/{version}',
        // formGatewayBaseUrl:'https://wvie-api-gw-uat2.optum.com',
        csGateway: 'https://test2-nonprod-oscar-k8s-ctc-dmz-nonprod.optum.com',
        clientSchedulingURL: 'https://wvie-api-gw-test.optum.com',
        openId_loginUrl:
          'https://identity.nonprod.optumgovid.com/oidc/authorize?pfidpadapterid=OptumIdOIDCStage&client_id=CLPWV&response_type=code&redirect_uri={redirectUrl}&scope=openid%20profile%20email&state=portalSource%3DIEPortal&nonce=',
        openId_redirectUrl: 'http://localhost:9000/ie-portal-webapp/react/home',
        ofc36dRedirectUrl: 'https://wvie-clp-dev.optum.com/home',
        authorizationTokenEndpoint:
          'https://hcck8s-ie-apigw-dev.optum.com/api/ie/usrmgmt/v2/authorize/wv/token-v2?code={authorizationCode}',
        office365Url:
          'https://login.microsoftonline.com/wv.gov/oauth2/v2.0/authorize?client_id=329242b9-e19b-495c-9a8c-72c497b6d7c1&response_type=code&scope=openid%20offline_access%20https%3A%2F%2Fgraph.microsoft.com%2Fcalendars.read&redirect_uri=https://wvie-clp-dev.optum.com/home',
        refreshTokenEndpoint:
          'https://hcck8s-ie-apigw-tst.optum.com/api/ie/usrmgmt/v2/authorize/wv/token/refresh',
        clientPortalUrl: 'https://wvie-client-cs-test.optum.com/',
        // CS profile
        openId_localhostAccount: {
          uuid: "54600880-2ddf-4b0f-81c5-a53492858dad",
          userId: "clpNvigation",
          firstName: "Clp",
          lastName: "Navigation",
          dob: "1984-11-12",
          email: "clpnavigation@getnada.com",
          defaultUserRoleId: null
        }

        // {
        //   dob: "1992-08-01",
        //   email: "naresh.cherukuri@optum.com",
        //   firstName: "Adam",
        //   lastName: "Bose",
        //   userId: "CWEmp5",
        //   uuid: "c20c0c67-cebb-4acb-8c24-07376a8dd280",
        //   defaultUserRoleId: null
        // }
        // IE Profile
        // openId_localhostAccount: {
        //   dob: '1980-08-01',
        //   email: 'renuka.gorre@optum.com',
        //   firstName: 'Kamal',
        //   lastName: 'Test',
        //   userId: 'kamal-testing-clp',
        //   uuid: 'de44be39-ff09-47b9-85fc-def9ba1e3dae',
        //   defaultUserRoleId: null,
        // },
      };
      break;

    case 'NON-OFEDEV':
      baseConfig = {
        ...baseConfig,
        basePath: '',
        matomoUrl: null,
        matomoSiteId: 37,
        matomoCustomDimensionGovIdUsername: 1,
        matomoCustomDimensionCorrelationId: 2,
        //gatewayWvUrl: 'https://wvie-api-gw-dev.optum.com',
        gatewayIeUrl: 'https://hcck8s-ie-apigw-dev.optum.com',
        //gatewayMDMWvUrl: 'https://wvie-api-gw-dev.optum.com',
        clientSchedulingURL: 'https://wvie-apigw-dev-dmz.optum.com',
        csGateway: 'https://dev2-nonprod-oscar-k8s-ctc-dmz-nonprod.optum.com',
        formsBaseUrl:
          'https://wvie-apigw-dev-dmz.optum.com/api/ie/forms/wv/{version}',
        formGatewayBaseUrl: 'https://agency-portal-fe-wvie-dev-k8s-ctc-dmz-nonprod.optum.com',
        openId_loginUrl:
          'https://identity.nonprod.optumgovid.com/oidc/authorize?pfidpadapterid=OptumIdOIDCStage&client_id=CLPWV&response_type=code&redirect_uri={redirectUrl}&scope=openid%20profile%20email&state=portalSource%3DIEPortal&nonce=',
        ofc36dRedirectUrl: 'https://wvie-clp-dev.optum.com/home',
        openId_redirectUrl:
          'https://wvie-clpcs-dev.optum.com/ie-portal-webapp/react/home',
        office365Url:
          'https://login.microsoftonline.com/wv.gov/oauth2/v2.0/authorize?client_id=329242b9-e19b-495c-9a8c-72c497b6d7c1&response_type=code&scope=openid%20offline_access%20https%3A%2F%2Fgraph.microsoft.com%2Fcalendars.read&redirect_uri=https://wvie-clp-dev.optum.com/home',
        authorizationTokenEndpoint:
          'https://hcck8s-ie-apigw-dev.optum.com/api/ie/usrmgmt/v2/authorize/wv/token-v2?code={authorizationCode}',
        refreshTokenEndpoint:
          'https://hcck8s-ie-apigw-dev.optum.com/api/ie/usrmgmt/v2/authorize/wv/token/refresh',
        cincomUrl: 'https://apved54358.dmzmgmt.uhc.com:9380/WV_Dev02_CEWeb/api',
        apTargetPortalName: 'wvnsfap',
        rdpFlexiLink: 'https://flexi.wvpath.org/RDWeb',
        blueZoneLink: 'blueZone:',
        clientPortalUrl: 'https://wvie-client-cs-test.optum.com/',
      };
      break;

    case 'NON-OFETEST':
      baseConfig = {
        ...baseConfig,
        basePath: '',
        matomoUrl: null,
        matomoSiteId: 43,
        matomoCustomDimensionGovIdUsername: 1,
        matomoCustomDimensionCorrelationId: 2,
        //gatewayWvUrl: 'https://wvie-api-gw-test.optum.com',
        gatewayIeUrl: 'https://hcck8s-ie-apigw-tst.optum.com',
        gatewayMDMWvUrl: 'https://wvie-api-gw-test.optum.com',
        csGateway: 'https://test2-nonprod-oscar-k8s-ctc-dmz-nonprod.optum.com',
        clientSchedulingURL: 'https://wvie-apigw-test-dmz.optum.com',
        formsBaseUrl:
          'https://wvie-apigw-test-dmz.optum.com/api/ie/forms/wv/{version}',
        openId_loginUrl:
          'https://identity.nonprod.optumgovid.com/oidc/authorize?pfidpadapterid=OptumIdOIDCStage&client_id=CLPWV&response_type=code&redirect_uri={redirectUrl}&scope=openid%20profile%20email&state=portalSource%3DIEPortal&nonce=',
        ofc36dRedirectUrl: 'https://wvie-clp-test.optum.com/home',
        office365Url:
          'https://login.microsoftonline.com/wv.gov/oauth2/v2.0/authorize?client_id=329242b9-e19b-495c-9a8c-72c497b6d7c1&response_type=code&scope=openid%20offline_access%20https%3A%2F%2Fgraph.microsoft.com%2Fcalendars.read&redirect_uri=https://wvie-clp-test.optum.com/home',
        openId_redirectUrl:
          'https://wvie-clpcs-test.optum.com/ie-portal-webapp/react/home',
        authorizationTokenEndpoint:
          'https://hcck8s-ie-apigw-tst.optum.com/api/ie/usrmgmt/v2/authorize/wv/token-v2?code={authorizationCode}',
        refreshTokenEndpoint:
          'https://hcck8s-ie-apigw-tst.optum.com/api/ie/usrmgmt/v2/authorize/wv/token/refresh',
        cincomUrl: 'https://apved54358.dmzmgmt.uhc.com:9380/WV_Dev02_CEWeb/api',
        apTargetPortalName: 'wvnsfap',
        rdpFlexiLink: 'https://flexi.wvpath.org/RDWeb',
        clientPortalUrl: 'https://wvie-client-cs-test.optum.com/',
      };
      break;

    case 'OFEDEV':
      baseConfig = {
        ...baseConfig,
        basePath: '',
        matomoUrl: null,
        matomoSiteId: 27,
        matomoCustomDimensionGovIdUsername: 1,
        matomoCustomDimensionCorrelationId: 2,
        gatewayWvUrl: 'https://wvie-api-gw-dev.optum.com',
        gatewayIeUrl: 'https://ie-api-gw-int1.optum.com',
        gatewayMDMWvUrl: 'https://wvie-api-gw-dev.optum.com',
        csGateway: 'https://wvie-childsupport-dev.optum.com',
        clientSchedulingURL: 'https://wvie-api-gw-dev.optum.com',
        formsBaseUrl:
          'https://wvie-api-gw-dev2.optum.com/api/ie/forms/wv/{version}',
        openId_loginUrl:
          'https://identity.nonprod.optumgovid.com/oidc/authorize?pfidpadapterid=OptumIdOIDCStage&client_id=CLPWV&response_type=code&redirect_uri={redirectUrl}&scope=openid%20profile%20email&state=portalSource%3DIEPortal&nonce=',
        ofc36dRedirectUrl: 'https://wvie-clp-dev.optum.com/home',
        openId_redirectUrl:
          'https://wvie-clp-dev.optum.com/ie-portal-webapp/react/home',
        office365Url:
          'https://login.microsoftonline.com/wv.gov/oauth2/v2.0/authorize?client_id=329242b9-e19b-495c-9a8c-72c497b6d7c1&response_type=code&scope=openid%20offline_access%20https%3A%2F%2Fgraph.microsoft.com%2Fcalendars.read&redirect_uri=https://wvie-clp-dev.optum.com/home',
        authorizationTokenEndpoint:
          'https://ie-api-gw-int1.optum.com/api/ie/usrmgmt/v2/authorize/wv/token-v2?code={authorizationCode}',
        refreshTokenEndpoint:
          'https://ie-api-gw-int1.optum.com/api/ie/usrmgmt/v2/authorize/wv/token/refresh',
        cincomUrl: 'https://wvie-cincom-sit1.optum.com:8380/WV_Tst02_CEWeb/api',
        apTargetPortalName: 'wvnsfdevap',
        rdpFlexiLink: 'https://flexi.wvpath.org/RDWeb',
        clientPortalUrl: 'https://wvie-client-cs-test.optum.com/',
      };
      break;

    case 'OFETEST':
      baseConfig = {
        ...baseConfig,
        basePath: '',
        matomoUrl: null,
        matomoSiteId: 11,
        matomoCustomDimensionGovIdUsername: 1,
        matomoCustomDimensionCorrelationId: 2,
        gatewayWvUrl: 'https://wvie-api-gw-test.optum.com',
        gatewayIeUrl: 'https://ie-api-gw-test.optum.com',
        gatewayMDMWvUrl: 'https://wvie-api-gw-test.optum.com',
        csGateway: 'https://wvie-childsupport-test.optum.com',
        clientSchedulingURL: 'https://wvie-api-gw-test.optum.com',
        formsBaseUrl:
          'https://wvie-api-gw-test2.optum.com/api/ie/forms/wv/{version}',
        formGatewayBaseUrl:
          'https://wvie-api-gw-test2.optum.com',
        openId_loginUrl:
          'https://identity.nonprod.optumgovid.com/oidc/authorize?pfidpadapterid=OptumIdOIDCStage&client_id=CLPWV&response_type=code&redirect_uri={redirectUrl}&scope=openid%20profile%20email&state=portalSource%3DIEPortal&nonce=',
        ofc36dRedirectUrl: 'https://wvie-clp-test.optum.com/home',
        office365Url:
          'https://login.microsoftonline.com/wv.gov/oauth2/v2.0/authorize?client_id=329242b9-e19b-495c-9a8c-72c497b6d7c1&response_type=code&scope=openid%20offline_access%20https%3A%2F%2Fgraph.microsoft.com%2Fcalendars.read&redirect_uri=https://wvie-clp-test.optum.com/home',
        openId_redirectUrl:
          'https://wvie-clp-test.optum.com/ie-portal-webapp/react/home',
        authorizationTokenEndpoint:
          'https://ie-api-gw-test.optum.com/api/ie/usrmgmt/v2/authorize/wv/token-v2?code={authorizationCode}',
        refreshTokenEndpoint:
          'https://ie-api-gw-test.optum.com/api/ie/usrmgmt/v2/authorize/wv/token/refresh',
        cincomUrl: 'https://wvie-cincom-sit1.optum.com:8380/WV_Tst02_CEWeb/api',
        apTargetPortalName: 'wvnsftstap',
        rdpFlexiLink: 'https://flexi.wvpath.org/RDWeb',
        clientPortalUrl: 'https://wvie-client-cs-test.optum.com/',
      };
      break;

    case 'OFESIT':
      baseConfig = {
        ...baseConfig,
        basePath: '',
        matomoUrl: null,
        matomoSiteId: 17,
        matomoCustomDimensionGovIdUsername: 1,
        matomoCustomDimensionCorrelationId: 2,
        gatewayWvUrl: 'https://wvie-api-gw-sit1.optum.com',
        gatewayIeUrl: 'https://ie-api-gw-sit.optum.com',
        gatewayMDMWvUrl: 'https://wvie-api-gw-sit1.optum.com',
        clientSchedulingURL: 'https://wvie-api-gw-sit1.optum.com',
        csGateway: 'https://wvie-childsupport-sit1.optum.com',
        formsBaseUrl:
          'https://wvie-api-gw-sit2.optum.com/api/ie/forms/wv/{version}',
        formGatewayBaseUrl:
          'https://wvie-api-gw-sit2.optum.com',
        openId_loginUrl:
          'https://identity.nonprod.optumgovid.com/oidc/authorize?pfidpadapterid=OptumIdOIDCStage&client_id=CLPWV&response_type=code&redirect_uri={redirectUrl}&scope=openid%20profile%20email&state=portalSource%3DIEPortal&nonce=',
        ofc36dRedirectUrl: 'https://wvie-clp-sit1.optum.com/home',
        office365Url:
          'https://login.microsoftonline.com/wv.gov/oauth2/v2.0/authorize?client_id=329242b9-e19b-495c-9a8c-72c497b6d7c1&response_type=code&scope=openid%20offline_access%20https%3A%2F%2Fgraph.microsoft.com%2Fcalendars.read&redirect_uri=https://wvie-clp-sit1.optum.com/home',
        openId_redirectUrl:
          'https://wvie-clp-sit1.optum.com/ie-portal-webapp/react/home',
        authorizationTokenEndpoint:
          'https://ie-api-gw-sit.optum.com/api/ie/usrmgmt/v2/authorize/wv/token-v2?code={authorizationCode}',
        refreshTokenEndpoint:
          'https://ie-api-gw-sit.optum.com/api/ie/usrmgmt/v2/authorize/wv/token/refresh',
        cincomUrl: 'https://wvie-cincom-sit1.optum.com:8380/WV_Tst02_CEWeb/api',
        apTargetPortalName: 'wvnsfsitap',
        rdpFlexiLink: 'https://flexi.wvpath.org/RDWeb',
        clientPortalUrl: 'https://wvie-client-cs-test.optum.com/',
      };
      break;

    case 'OFESIT-TT':
      baseConfig = {
        ...baseConfig,
        basePath: '',
        matomoUrl: null,
        matomoSiteId: 49,
        matomoCustomDimensionGovIdUsername: 1,
        matomoCustomDimensionCorrelationId: 2,
        gatewayWvUrl: 'https://wvie-api-gw-ttsit.optum.com',
        gatewayIeUrl: 'https://ie-api-gw-ttsit.optum.com',
        gatewayMDMWvUrl: 'https://wvie-api-gw-ttsit.optum.com',
        clientSchedulingURL: 'https://wvie-api-gw-ttsit.optum.com',
        csGateway: 'https://wvie-childsupport-ttsit.optum.com',
        formsBaseUrl:
          'https://wvie-api-gw-ttsit.optum.com/api/ie/forms/wv/{version}',
        openId_loginUrl:
          'https://identity.nonprod.optumgovid.com/oidc/authorize?pfidpadapterid=OptumIdOIDCStage&client_id=CLPWV&response_type=code&redirect_uri={redirectUrl}&scope=openid%20profile%20email&state=portalSource%3DIEPortal&nonce=',
        ofc36dRedirectUrl: 'https://wvie-clp-ttsit.optum.com/home',
        office365Url:
          'https://login.microsoftonline.com/wv.gov/oauth2/v2.0/authorize?client_id=329242b9-e19b-495c-9a8c-72c497b6d7c1&response_type=code&scope=openid%20offline_access%20https%3A%2F%2Fgraph.microsoft.com%2Fcalendars.read&redirect_uri=https://wvie-clp-ttsit.optum.com/home',
        openId_redirectUrl:
          'https://wvie-clp-ttsit.optum.com/ie-portal-webapp/react/home',
        authorizationTokenEndpoint:
          'https://ie-api-gw-ttsit.optum.com/api/ie/usrmgmt/v2/authorize/wv/token-v2?code={authorizationCode}',
        refreshTokenEndpoint:
          'https://ie-api-gw-ttsit.optum.com/api/ie/usrmgmt/v2/authorize/wv/token/refresh',
        cincomUrl: 'https://wvie-cincom-sit1.optum.com:8380/WV_Tst02_CEWeb/api',
        apTargetPortalName: 'wvnsfttsitap',
        rdpFlexiLink: 'https://flexi.wvpath.org/RDWeb',
        clientPortalUrl: 'https://wvie-client-cs-test.optum.com/',
      };
      break;

    case 'OFEUAT':
      baseConfig = {
        ...baseConfig,
        basePath: '',
        matomoUrl: null,
        matomoSiteId: 22,
        matomoCustomDimensionGovIdUsername: 1,
        matomoCustomDimensionCorrelationId: 2,
        gatewayWvUrl: 'https://wvie-api-gw-uat.optum.com',
        gatewayIeUrl: 'https://ie-api-gw-uat.optum.com',
        gatewayMDMWvUrl: 'https://wvie-api-gw-uat.optum.com',
        clientSchedulingURL: 'https://wvie-api-gw-uat.optum.com',
        csGateway: 'https://wvie-childsupport-uat.optum.com',
        formsBaseUrl:
          'https://wvie-api-gw-uat2.optum.com/api/ie/forms/wv/{version}',
        openId_loginUrl:
          'https://identity.nonprod.optumgovid.com/oidc/authorize?pfidpadapterid=OptumIdOIDCStage&client_id=CLPWV&response_type=code&redirect_uri={redirectUrl}&scope=openid%20profile%20email&state=portalSource%3DIEPortal&nonce=',
        ofc36dRedirectUrl: 'https://wvie-clp-uat.optum.com/home',
        office365Url:
          'https://login.microsoftonline.com/wv.gov/oauth2/v2.0/authorize?client_id=329242b9-e19b-495c-9a8c-72c497b6d7c1&response_type=code&scope=openid%20offline_access%20https%3A%2F%2Fgraph.microsoft.com%2Fcalendars.read&redirect_uri=https://wvie-clp-uat.optum.com/home',
        openId_redirectUrl:
          'https://wvie-clp-uat.optum.com/ie-portal-webapp/react/home',
        authorizationTokenEndpoint:
          'https://ie-api-gw-uat.optum.com/api/ie/usrmgmt/v2/authorize/wv/token-v2?code={authorizationCode}',
        refreshTokenEndpoint:
          'https://ie-api-gw-uat.optum.com/api/ie/usrmgmt/v2/authorize/wv/token/refresh',
        cincomUrl: 'https://wvie-cincom-uat1.optum.com:8380/WV_Uat01_CEWeb/api',
        apTargetPortalName: 'wvnsfuatap',
        rdpFlexiLink: 'https://flexi.wvpath.org/RDWeb',
        clientPortalUrl: 'https://wvie-client-cs-uat.optum.com/',
      };
      break;

    case 'OFEPERF':
      baseConfig = {
        ...baseConfig,
        basePath: '',
        matomoUrl: null,
        matomoSiteId: null,
        matomoCustomDimensionGovIdUsername: null,
        matomoCustomDimensionCorrelationId: null,
        gatewayWvUrl: 'https://wvie-api-gw-perf-stg.optum.com',
        gatewayIeUrl: 'https://ie-api-gw-perf.optum.com',
        gatewayMDMWvUrl: 'https://wvie-api-gw-uat.optum.com',
        clientSchedulingURL: 'https://wvie-api-gw-perf-stg.optum.com',
        csGateway: 'https://wvie-childsupport-perf-stg.optum.com',
        formsBaseUrl:
          'https://wvie-api-gw-perf-stg2.optum.com/api/ie/forms/wv/{version}',
        openId_loginUrl:
          'https://identity.nonprod.optumgovid.com/oidc/authorize?pfidpadapterid=OptumIdOIDCStage&client_id=CLPWV&response_type=code&redirect_uri={redirectUrl}&scope=openid%20profile%20email&state=portalSource%3DIEPortal&nonce=',
        ofc36dRedirectUrl: 'https://wvie-clp-ttsit.optum.com/home',
        office365Url:
          'https://login.microsoftonline.com/wv.gov/oauth2/v2.0/authorize?client_id=329242b9-e19b-495c-9a8c-72c497b6d7c1&response_type=code&scope=openid%20offline_access%20https%3A%2F%2Fgraph.microsoft.com%2Fcalendars.read&redirect_uri=https://wvie-clp-perf-stg.optum.com/home',
        openId_redirectUrl:
          'https://wvie-clp-perf-stg.optum.com/ie-portal-webapp/react/home',
        authorizationTokenEndpoint:
          'https://ie-api-gw-perf.optum.com/api/ie/usrmgmt/v2/authorize/wv/token-v2?code={authorizationCode}',
        refreshTokenEndpoint:
          'https://ie-api-gw-perf.optum.com/api/ie/usrmgmt/v2/authorize/wv/token/refresh',
        cincomUrl: 'https://wvie-cincom-sit1.optum.com:8380/WV_Tst02_CEWeb/api',
        apTargetPortalName: 'wvnsfsitap',
        clientPortalUrl: 'https://wvie-client-cs-uat.optum.com/',// TODO : ONCE WE GET NEW URL
      };
      break;

    case 'TRAINING':
      baseConfig = {
        ...baseConfig,
        basePath: '',
        matomoUrl: null,
        matomoSiteId: 13,
        matomoCustomDimensionGovIdUsername: 1,
        matomoCustomDimensionCorrelationId: 2,
        gatewayWvUrl: 'https://wvie-api-gw-stage.optum.com',
        gatewayIeUrl: 'https://ie-api-gw-stage.optum.com',
        gatewayMDMWvUrl: 'https://wvie-api-gw-stage.optum.com',
        clientSchedulingURL: 'https://wvie-api-gw-stage.optum.com',
        csGateway: 'https://wvie-api-gw-stage.optum.com',
        formsBaseUrl:
          'https://wvie-api-gw-stage2.optum.com/api/ie/forms/wv/{version}',
        openId_loginUrl:
          'https://identity.nonprod.optumgovid.com/oidc/authorize?pfidpadapterid=OptumIdOIDCStage&client_id=CLPWV&response_type=code&redirect_uri={redirectUrl}&scope=openid%20profile%20email&state=portalSource%3DIEPortal&nonce=',
        ofc36dRedirectUrl: 'https://wvie-clp-stage.optum.com/home',
        office365Url:
          'https://login.microsoftonline.com/wv.gov/oauth2/v2.0/authorize?client_id=329242b9-e19b-495c-9a8c-72c497b6d7c1&response_type=code&scope=openid%20offline_access%20https%3A%2F%2Fgraph.microsoft.com%2Fcalendars.read&redirect_uri=https://wvie-clp-stage.optum.com/home',
        openId_redirectUrl:
          'https://wvie-clp-stage.optum.com/ie-portal-webapp/react/home',
        authorizationTokenEndpoint:
          'https://ie-api-gw-stage.optum.com/api/ie/usrmgmt/v2/authorize/wv/token-v2?code={authorizationCode}',
        refreshTokenEndpoint:
          'https://ie-api-gw-stage.optum.com/api/ie/usrmgmt/v2/authorize/wv/token/refresh',
        cincomUrl: 'https://wvie-cincom-uat1.optum.com:8380/WV_Uat01_CEWeb/api',
        apTargetPortalName: 'wvnsftrnap',
        rdpFlexiLink: 'https://flexi.wvpath.org/RDWeb',
        clientPortalUrl: 'https://wvie-client-cs-uat.optum.com/',// TODO : ONCE WE GET NEW URL
      };
      break;

    case 'OFEMPROD':
      baseConfig = {
        ...baseConfig,
        basePath: '',
        matomoUrl: null,
        matomoSiteId: 55,
        matomoCustomDimensionGovIdUsername: 1,
        matomoCustomDimensionCorrelationId: 2,
        gatewayIncidentURL: 'https://wvie-api-gw-mprod.optum.com',
        gatewayWvUrl: 'https://wvie-api-gw-mprod.optum.com',
        gatewayIeUrl: 'https://ie-api-gw-mprod.optum.com',
        clientSchedulingURL: 'https://wvie-api-gw-mprod.optum.com',
        csGateway: 'https://wvie-api-gw-mprod.optum.com',
        formsBaseUrl:
          'https://wvie-api-gw2-mprod.optum.com/api/ie/forms/wv/{version}',
        openId_loginUrl:
          'https://identity.nonprod.optumgovid.com/oidc/authorize?pfidpadapterid=OptumIdOIDCStage&client_id=CLPWV&response_type=code&redirect_uri={redirectUrl}&scope=openid%20profile%20email&state=portalSource%3DIEPortal&nonce=',
        ofc36dRedirectUrl: 'https://wvie-clp-mprod.optum.com/home',
        office365Url:
          'https://login.microsoftonline.com/wv.gov/oauth2/v2.0/authorize?client_id=329242b9-e19b-495c-9a8c-72c497b6d7c1&response_type=code&scope=openid%20offline_access%20https%3A%2F%2Fgraph.microsoft.com%2Fcalendars.read&redirect_uri=https://wvie-clp-mprod.optum.com/home',
        openId_redirectUrl:
          'https://wvie-clp-mprod.optum.com/ie-portal-webapp/react/home',
        authorizationTokenEndpoint:
          'https://ie-api-gw-mprod.optum.com/api/ie/usrmgmt/v2/authorize/wv/token-v2?code={authorizationCode}',
        refreshTokenEndpoint:
          'https://ie-api-gw-mprod.optum.com/api/ie/usrmgmt/v2/authorize/wv/token/refresh',
        cincomUrl: 'https://wvie-cincom-uat1.optum.com:8380/WV_Uat01_CEWeb/api',
        apTargetPortalName: 'wvnsfmprodap',
        rdpFlexiLink: 'https://flexi.wvpath.wv.gov/RDWeb',
        clientPortalUrl: 'https://wvie-client-cs-uat.optum.com/',// TODO : ONCE WE GET NEW URL
      };
      break;

    case 'OFEPROD':
      baseConfig = {
        ...baseConfig,
        basePath: '',
        matomoUrl: null,
        matomoSiteId: null,
        matomoCustomDimensionGovIdUsername: null,
        matomoCustomDimensionCorrelationId: null,
        gatewayWvUrl: 'https://wvie-api-gw2.optum.com',
        gatewayIeUrl: 'https://ie-api-gw2.optum.com',
        gatewayMDMWvUrl: 'https://wvie-api-gw2.optum.com',
        clientSchedulingURL: 'https://wvie-api-gw2.optum.com',
        csGateway: 'https://wvie-api-gw2.optum.com',
        formsBaseUrl:
          'https://wvie-api-gw2.optum.com/api/ie/forms/wv/{version}',
        openId_loginUrl:
          'https://identity.optumgovid.com/oidc/authorize?pfidpadapterid=OptumIdOIDCStage&client_id=CLPWV&response_type=code&redirect_uri={redirectUrl}&scope=openid%20profile%20email&state=portalSource%3DIEPortal&nonce=',
        ofc36dRedirectUrl: 'https://wvie-clp.optum.com/home',
        office365Url:
          'https://login.microsoftonline.com/wv.gov/oauth2/v2.0/authorize?client_id=329242b9-e19b-495c-9a8c-72c497b6d7c1&response_type=code&scope=openid%20offline_access%20https%3A%2F%2Fgraph.microsoft.com%2Fcalendars.read&redirect_uri=https://wvie-clp.optum.com/home',
        openId_redirectUrl:
          'https://wvie-clp.optum.com/ie-portal-webapp/react/home',
        authorizationTokenEndpoint:
          'https://ie-api-gw2.optum.com/api/ie/usrmgmt/v2/authorize/wv/token-v2?code={authorizationCode}',
        refreshTokenEndpoint:
          'https://ie-api-gw2.optum.com/api/ie/usrmgmt/v2/authorize/wv/token/refresh',
        cincomUrl: 'https://cincom.wvpath.wv.gov:8380/WV_Prod01_CEWeb/api',
        apTargetPortalName: 'wvnsfprodap',
        rdpFlexiLink: 'https://flexi.wvpath.wv.gov/RDWeb',
        clientPortalUrl: 'https://wvie-client-cs-uat.optum.com/',// TODO : ONCE WE GET NEW URL
      };
      break;

    case 'OFEPRODGOV':
      baseConfig = {
        ...baseConfig,
        basePath: '',
        matomoUrl: null,
        matomoSiteId: null,
        matomoCustomDimensionGovIdUsername: null,
        matomoCustomDimensionCorrelationId: null,
        gatewayWvUrl: 'https://wvie-api-gw2.optum.com',
        gatewayIeUrl: 'https://ie-api-gw2.optum.com',
        gatewayMDMWvUrl: 'https://wvie-api-gw2.optum.com',
        clientSchedulingURL: 'https://wvie-api-gw2.optum.com',
        csGateway: 'https://wvie-api-gw2.optum.com',
        formsBaseUrl:
          'https://wvie-api-gw2.optum.com/api/ie/forms/wv/{version}',
        openId_loginUrl:
          'https://identity.optumgovid.com/oidc/authorize?pfidpadapterid=OptumIdOIDCStage&client_id=CLPWV&response_type=code&redirect_uri={redirectUrl}&scope=openid%20profile%20email&state=portalSource%3DIEPortal&nonce=',
        ofc36dRedirectUrl: 'https://wvie-clp.optum.com/home',
        office365Url:
          'https://login.microsoftonline.com/wv.gov/oauth2/v2.0/authorize?client_id=329242b9-e19b-495c-9a8c-72c497b6d7c1&response_type=code&scope=openid%20offline_access%20https%3A%2F%2Fgraph.microsoft.com%2Fcalendars.read&redirect_uri=https://wvie-clp.optum.com/home',
        openId_redirectUrl:
          'https://agencyportal.wvpath.wv.gov/ie-portal-webapp/react/home',
        authorizationTokenEndpoint:
          'https://ie-api-gw2.optum.com/api/ie/usrmgmt/v2/authorize/wv/token-v2?code={authorizationCode}',
        refreshTokenEndpoint:
          'https://ie-api-gw2.optum.com/api/ie/usrmgmt/v2/authorize/wv/token/refresh',
        cincomUrl: 'https://cincom.wvpath.wv.gov:8380/WV_Prod01_CEWeb/api',
        apTargetPortalName: 'wvnsfprodap',
        rdpFlexiLink: 'https://flexi.wvpath.wv.gov/RDWeb',
        clientPortalUrl: 'https://wvie-client-cs-uat.optum.com/',// TODO : ONCE WE GET NEW URL
      };
      break;

    case 'OFE-CS-PRESIT':
      baseConfig = {
        ...baseConfig,
        basePath: '',
        matomoUrl: null,
        matomoSiteId: null,
        matomoCustomDimensionGovIdUsername: null,
        matomoCustomDimensionCorrelationId: null,
        gatewayWvUrl: 'https://wvie-api-gw-sit1.optum.com',
        gatewayIeUrl: 'https://ie-api-gw-sit.optum.com',
        clientSchedulingURL: 'https://wvie-api-gw-sit1.optum.com',
        csGateway: 'https://wvie-childsupport-sit1.optum.com',
        formsBaseUrl:
          'https://wvie-api-gw-sit2.optum.com/api/ie/forms/wv/{version}',
        openId_loginUrl:
          'https://identity.nonprod.optumgovid.com/oidc/authorize?pfidpadapterid=OptumIdOIDCStage&client_id=CLPWV&response_type=code&redirect_uri={redirectUrl}&scope=openid%20profile%20email&state=portalSource%3DIEPortal&nonce=',
        ofc36dRedirectUrl: 'https://wvie-clp-presit.optum.com/home',
        office365Url:
          'https://login.microsoftonline.com/wv.gov/oauth2/v2.0/authorize?client_id=329242b9-e19b-495c-9a8c-72c497b6d7c1&response_type=code&scope=openid%20offline_access%20https%3A%2F%2Fgraph.microsoft.com%2Fcalendars.read&redirect_uri=https://wvie-clp-presit.optum.com/home',
        openId_redirectUrl:
          'https://wvie-clp-presit.optum.com/ie-portal-webapp/react/home',
        authorizationTokenEndpoint:
          'https://ie-api-gw-sit.optum.com/api/ie/usrmgmt/v2/authorize/wv/token-v2?code={authorizationCode}',
        refreshTokenEndpoint:
          'https://ie-api-gw-sit.optum.com/api/ie/usrmgmt/v2/authorize/wv/token/refresh',
        cincomUrl: 'https://wvie-cincom-sit1.optum.com:8380/WV_Tst02_CEWeb/api',
        apTargetPortalName: 'wvnsfsitap',
        rdpFlexiLink: 'https://flexi.wvpath.org/RDWeb'
      };
      break;

    default:
      baseConfig = {
        ...baseConfig,
        gatewayBaseUrl: 'https://ie-api-gw-dev.optum.com',
      };
  }

  let finalConfig = null;
  if (getTenantConfig) {
    const tenantConfig = getTenantConfig(baseConfig);
    //Merge the tenant configuration after base, so it will take priority.
    finalConfig = {...baseConfig, ...tenantConfig}
  } else {
    finalConfig = baseConfig;
  }

  // apply tenant.id and tenant.code to endpoints
  Object.keys(finalConfig).forEach(key => {
    if (typeof finalConfig[key] === 'string')
      finalConfig[key] = finalConfig[key]
        .replace('{tenantId}', baseConfig.tenant_id)
        .replace('{formPath}', baseConfig['formPath'])
        .replace('{tenantCode}', baseConfig.tenant_c)
        .replace('{gatewayBaseUrl}', baseConfig.gatewayIeUrl)
        .replace(
          '{userAccessFormBaseUrl}',
          baseConfig.formsBaseUrl.replace('{version}', '2.0'),
        )
        .replace('{appProcessingPath}', baseConfig.appProcessingPath)
        .replace('{domainPath}', baseConfig['domainPath'])
        .replace('{formGatewayBaseUrl}', baseConfig['formGatewayBaseUrl'])

  });
  return finalConfig;
}

export function determineBaseEnvironment() {
  // More secure URL check:
  const urlHost = new URL(window.location.href).host;
  switch (urlHost) {
    case "localhost":
      return "LOCAL";
    case "wvie-clpcs-dev.optum.com":
      return "NON-OFEDEV";
    case "wvie-clpcs-test.optum.com":
      return "NON-OFETEST";
    case "wvie-clp-dev.optum.com":
      return "OFEDEV";
    case "wvie-clp-test.optum.com":
      return "OFETEST";
    case "wvie-clp-sit1.optum.com":
      return "OFESIT";
    case "wvie-clp-ttsit.optum.com":
      return "OFESIT-TT";
    case "wvie-clp-uat.optum.com":
      return "OFEUAT";
    case "wvie-clp-perf-stg.optum.com":
      return "OFEPERF";
    case "wvie-clp-stage.optum.com":
      return "TRAINING";
    case "wvie-clp-presit.optum.com":
      return "OFE-CS-PRESIT";
    case "wvie-clp.optum.com.optum.com":
      return "OFEPROD";
    case "wvie-clp-mprod.optum.com":
      return "OFEMPROD";
    case "agencyportal.wvpath.wv.gov":
      return "OFEPRODGOV";
    case "www.agencyportal.wvpath.wv.gov":
      return "OFEPRODGOV";
    default:
      return "LOCAL";
  }
}

function determineBaseTenant(environment) {
  //We simulating tenant in the core product. Try to determine tenant in the following order, taking the first resolved.
  //1. URL prefix
  //2. URL "tenant" query parameter
  //3. Last known tenant for this environment, saved to local storage.

  const { url, query } = queryString.parseUrl(window.location.href);

  //Determine the tenant based on the URL prefix
  const prefixMatch = url
    .toLowerCase()
    .match('^(?:(?:http|https)://)ie-([a-zA-Z][a-zA-Z])-cp');

  if (prefixMatch && prefixMatch[1]) {
    return prefixMatch[1].toUpperCase();
  }

  //Determine the tenant based on the tenant URL parameter
  if (query && query.tenant) {
    return query.tenant.toUpperCase();
  }

  //Determine the tenant based on what was last saved to local storage.
  let sessionStorageTenant = SessionStorage.getItem(`IE-${environment}-CLP-TENANT`);

  if (sessionStorageTenant && sessionStorageTenant.length > 0) {
    return sessionStorageTenant.toUpperCase();
  }

  //Default to WV if somehow we have not determined the tenant.
  return 'WV';
}