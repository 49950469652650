const assetBase = process.env.ASSET_BASE;

export function isImplemented() {
  ///TODO: In West Virginia's implementation fork, return true here.
  return false;
}

export function getConfig(baseConfig: any) {
  let config: any = {
    tenant: {
      id: 1,
      code: 'WV',
      name: 'West Virginia',
      styles: {
        theme: assetBase + '/css/blue-theme.css',
      },
      fetcher: {
        retries: 5,
        timeoutMs: 30000,
      },
      externalAuth: {
        checkHasPin: false, // was true
      },
      timeZone: 'America/New_York',
    },
  };

  //Retrieve environment-level config.
  switch (baseConfig.environment) {
    case 'LOCAL':
      config = {
        ...config,
        //"gatewayBaseUrl": "https://wv-ie-api-gw-tst.optum.com" //TODO
      };
      break;
    case 'DEV':
      config = {
        ...config,
        //"gatewayBaseUrl": "https://wv-ie-api-gw-dev.optum.com" //TODO
      };
      break;
    case 'TEST':
      config = {
        ...config,
        //"gatewayBaseUrl": "https://wv-ie-api-gw-tst.optum.com" //TODO
      };
      break;
    case 'STAGING':
      config = {
        ...config,
        //"gatewayBaseUrl": "https://wv-ie-api-gw-stg.optum.com" //TODO
      };
      break;
    case 'DEMO':
      config = {
        ...config,
        //"gatewayBaseUrl": "https://wv-ie-api-gw-demo.optum.com" //TODO
      };
      break;
    default:
      config = {
        ...config,
        //"gatewayBaseUrl": "https://wv-ie-api-gw-dev.optum.com" //TODO
      };
  }

  return config;
}

export function determineEnvironment() {
  const urlHost = new URL(window.location.href).host;
  switch (urlHost) {
    case "localhost":
      return "LOCAL";
    case "wvie-clpcs-dev.optum.com":
      return "NON-OFEDEV";
    case "wvie-clpcs-test.optum.com":
      return "NON-OFETEST";
    case "wvie-clp-dev.optum.com":
      return "OFEDEV";
    case "wvie-clp-test.optum.com":
      return "OFETEST";
    case "wvie-clp-sit1.optum.com":
      return "OFESIT";
    case "wvie-clp-ttsit.optum.com":
      return "OFESIT-TT";
    case "wvie-clp-uat.optum.com":
      return "OFEUAT";
    case "wvie-clp-perf-stg.optum.com":
      return "OFEPERF";
    case "wvie-clp-stage.optum.com":
      return "TRAINING";
    case "wvie-clp-presit.optum.com":
      return "OFE-CS-PRESIT";
    case "wvie-clp.optum.com.optum.com":
      return "OFEPROD";
    case "wvie-clp-mprod.optum.com":
      return "OFEMPROD";
    case "agencyportal.wvpath.wv.gov":
      return "OFEPRODGOV";
    case "www.agencyportal.wvpath.wv.gov":
      return "OFEPRODGOV";
    default:
      return null;
  }
}
