import { default as React, Suspense, lazy } from 'react';
import { render } from 'react-dom';
import { config } from './config';

import 'whatwg-fetch';

const LazyRoot = lazy(() => import('./components/base/Root')) 
// import Root from './components/base/Root'
//Uncomment the following block to start capturing fetch responses from localhost
//NOTE: You must first create a folder called "log"
/*
if (process.env.NODE_ENV === 'development') {
  const originalFetch = (global as any).fetch
  import moment from 'moment-timezone'
  const baseUrl = process.env.PUBLIC_URL
  const fetchWithLogging = (endpoint: string, request) => {
    const timeStamp = moment().format('YYYYMMDDHHmmssSSS')
    return originalFetch(endpoint, request).then(response => {
      const responseClone = response.clone()
      response.json().then(response => {
        const fileName = 'fetchResponse-'
          .concat(timeStamp)
          .concat('-')
          .concat(request.method)
          .concat(
            endpoint
              .substring(
                endpoint.indexOf('/api/') > -1
                  ? endpoint.indexOf('/api/')
                  : endpoint.length
              )
              .replace('http://', '')
              .replace('https://', '')
              .replace('?', '')
              .replace(new RegExp('/', 'g'), '-')
          )
          .concat('.json')

        const logRequest = {
          fileName,
          content: response
        }
        originalFetch(baseUrl + '/devlog', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(logRequest)
        })
      })
      return responseClone
    })
  }
  ;(global as any).fetch = fetchWithLogging
  ;(window as any).fetch = fetchWithLogging
}
*/

const data = JSON.parse(sessionStorage.getItem(`IE-${config.environment}-CLP-WV-STATE`));

const redirectUri = config.openId_loginUrl.replace(
  '{redirectUrl}',
  encodeURIComponent(config.openId_redirectUrl),
);

async function setup() {
 render(
  <Suspense fallback={<div>Loading...</div>}>
    <LazyRoot />
  </Suspense>
 , document.getElementById('root'));
}

async function preSetup() {
  if(process.env.NODE_ENV === 'production'){
    if(window.location.href.includes('code=')){
      setup()
    } else if(data?.auth){
      const { auth } = data
      let date = new Date()
      let expired = data.auth.expiry <= date.getTime()
      if((auth && auth.isAuthenticated && auth.userAccount && !expired)){
        setup()
      } else {
        window.location.href = redirectUri;
      }
    } else {
      window.location.href = redirectUri;
    }
  } else {
    setup()
  }
}

preSetup()